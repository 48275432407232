import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FEATURES } from '@finmap/core-constants';

import FacebookPixelContext from '../../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import { selectCompanyCurrencyId } from '../../../../store/company/selectors';
import operationActions from '../../../../store/operations/actions';
import { getOperationProps } from '../../../../store/operations/selectors';
import {
  CreateTransferResponseData,
  OperationType,
} from '../../../../store/operations/types';
import { TransferProps } from './types';
import useMainPayload from './useMainPayload';
import useUnleash from '../../../../hooks/useUnleash';

function useCreateTransfer(props: TransferProps) {
  const {
    editProps,
    scheduled,
    isEdit,
    isCopy,
    shouldCreate,
    onCloseDialog,
    resetShouldCreate,
    shouldCloseDialog,
  } = props;
  const facebookContext = useContext(FacebookPixelContext);

  const {
    amount,
    account,
    toAccount,
    revertState,
    exchangeRate,
    currencyAmount,
    editOperationId,
    isCopyComponent,
  } = useSelector(getOperationProps);

  const dispatch = useDispatch();
  const location = useLocation();
  const companyCurrencyId = useSelector(selectCompanyCurrencyId);
  const isLongLoad = useUnleash(FEATURES.LONG_UPDATE_OPERATION);

  const mainPayloadProps = useMainPayload({ editProps, isEdit });

  let sum = amount ? parseFloat(amount) : undefined;
  let companyCurrencySum = currencyAmount
    ? parseFloat(currencyAmount)
    : undefined;

  if (account?.currency._id !== toAccount?.currency._id) {
    const sumValue = revertState ? sum : companyCurrencySum;

    companyCurrencySum = revertState ? companyCurrencySum : sum;
    sum = sumValue;
  }

  useEffect(() => {
    if (shouldCreate) {
      const payload: Omit<CreateTransferResponseData, 'companyId'> = {
        ...mainPayloadProps,
        scheduled,
        sum,
        isCopy,
        accountToId: toAccount ? toAccount._id : undefined,
        accountFromId: account ? account._id : undefined,
        companyCurrencySum,
        exchangeRate: exchangeRate ? parseFloat(exchangeRate) : undefined,
      };

      if (isEdit && editProps && editOperationId && !isCopyComponent) {
        dispatch(
          operationActions.updateOperation({
            id: editOperationId,
            // @ts-ignore
            payload: {
              type: OperationType.transfer,
              ...payload,
            },
            location,
            isLongLoad,
          }),
        );
      } else {
        dispatch(
          operationActions.createTransferOperation({
            props: payload,
            location,
            facebookContext,
          }),
        );
      }

      if (shouldCloseDialog) {
        if (shouldCloseDialog) {
          onCloseDialog();
        } else {
          resetShouldCreate();
        }
      } else {
        resetShouldCreate();
      }
    }
  }, [
    sum,
    props,
    amount,
    isEdit,
    isCopy,
    account,
    dispatch,
    location,
    scheduled,
    editProps,
    toAccount,
    revertState,
    exchangeRate,
    shouldCreate,
    onCloseDialog,
    currencyAmount,
    editOperationId,
    facebookContext,
    isCopyComponent,
    mainPayloadProps,
    resetShouldCreate,
    shouldCloseDialog,
    companyCurrencyId,
    companyCurrencySum,
    isLongLoad,
  ]);

  return null;
}

export default useCreateTransfer;
