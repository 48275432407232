import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactSortable } from 'react-sortablejs';
import { FEATURES } from '@finmap/core-constants';

import CustomButton from '../../components/Button';
import Dialog from '../../components/Dialog/Dialog';
import PreviewRegisterDialog from '../../components/PreviewRegisterDialog';
import { HelperPages } from '../../hooks/useHelpVideoLinks';
import usePermissions from '../../hooks/usePermissions';
import useSubscriptionActive from '../../hooks/useSubscriptionActive';
import useUnleash from '../../hooks/useUnleash';
import Storages, { StorageKey } from '../../services/Storages';
import accountActions from '../../store/accounts/actions';
import {
  getEditAccountInProgress,
  selectSettingsAccounts,
} from '../../store/accounts/selectors';
import { AccountValue } from '../../store/accounts/types';
import integrationActions from '../../store/integrations/actions';
import ExportItemsToExcel from '../Header/Settings/Components/ExportItemsToExcel';
import PosterAccountsDialog from '../Integrations/PosterDialog/posterAccountDialog';
import VideoHelper from '../VideoHelper';
import CreateDialog from './CreateAccountDialog';
import EditAccountDialog from './EditAccountDialog';
import IntegrationDialog from './IntegrationDialog';
import NordigenAccountsDialog from './NordigenAccountsDialog';
import ReconnectFailedDialog from './ReconnectFailedDialog';
import ReconnectSuccessDialog from './ReconnectSuccessDialog';
import RenderAccountRow from './RenderAccountRow';
import SaltedgeAccountsDialog from './SaltedgeAccountsDialog';
import { useStyles } from './styles';
import { Props } from './types';
import BelvoAccountsDialog from './BelvoAccountsDialog';

let timer: NodeJS.Timeout | null = null;

const ReactSortableComponent = ReactSortable as React.ElementType;

function AccountsDialog(props: Props) {
  const { onClose, initialShowCreate, fromSettings, isOpened = true } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const subscriptionActive = useSubscriptionActive();

  const accounts = useSelector(selectSettingsAccounts);
  const editInProgress = useSelector(getEditAccountInProgress);

  const { isEditableAccounts } = usePermissions();

  const prevEditInProgressState = useRef(editInProgress);
  const saltedgeIntegration = Storages.get(StorageKey.saltedge);

  const useOnlyNordigenService = useUnleash(FEATURES.USE_ONLY_NORDIGEN_SERVICE);

  const [items, setItems] = useState<AccountValue[]>([]);
  const [showCreateDialog, setShowCreateDialog] = useState(initialShowCreate);
  const [isReconnect, setIsReconnect] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showIntegrationsDialog, setShowIntegrationsDialog] = useState(false);
  const [currentAccount, setCurrentAccount] = useState<AccountValue | null>(
    null,
  );
  const [showReconnectSaltedgeDialog, setShowReconnectSaltedgeDialog] =
    useState(
      Boolean(
        saltedgeIntegration?.integrationId &&
          saltedgeIntegration?.integrationCredentialsId &&
          saltedgeIntegration?.connectionId,
      ),
    );
  const [showReconnectErrorDialog, setShowReconnectErrorDialog] = useState(
    Boolean(
      saltedgeIntegration?.integrationId && !saltedgeIntegration?.connectionId,
    ),
  );

  const [showPreviewRegisterDialog, setShowPreviewRegisterDialog] =
    useState(false);

  const handleClosePreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
  }, []);

  const handleOpenPreviewRegisterDialog = useCallback(() => {
    setShowPreviewRegisterDialog(true);
  }, []);

  const handleOpenEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const handleCloseEditDialog = useCallback(() => {
    setShowEditDialog(false);
  }, []);

  const handleOpenCreateDialog = useCallback(() => {
    setShowPreviewRegisterDialog(false);
    setShowCreateDialog(true);
  }, []);

  const handleClose = useCallback(() => {
    if (timer) {
      clearTimeout(timer);
    }

    onClose();
  }, [onClose]);

  const handleCloseCreateDialog = useCallback(() => {
    setShowCreateDialog(false);
  }, []);

  const handleCloseIntegrationsDialog = useCallback(() => {
    setShowIntegrationsDialog(false);
  }, []);

  const handleCloseReconnectSaltedgeDialog = useCallback(() => {
    setShowReconnectSaltedgeDialog(false);
    Storages.remove(StorageKey.saltedge);
  }, []);

  const handleCloseReconnectErrorDialog = useCallback(() => {
    setShowReconnectErrorDialog(false);
    Storages.remove(StorageKey.saltedge);
  }, []);

  const handleUpdate = useCallback(
    (name: string, balance: number, visible: boolean) => {
      if (currentAccount) {
        const account = {
          ...currentAccount,
          name,
          startingBalance: balance,
          visible,
        };

        dispatch(accountActions.updateGroupAccounts([account]));
      }
    },
    [dispatch, currentAccount],
  );

  const handleDelete = useCallback(
    (id: string) => {
      dispatch(accountActions.deleteAccount({ id }));

      handleCloseEditDialog();
    },
    [dispatch, handleCloseEditDialog],
  );

  const handleEditRow = useCallback(
    (id: string) => {
      if (id.startsWith('edit')) {
        const accId = id.substr(5);
        const account = items.find((i) => i._id === accId);

        if (account) {
          setCurrentAccount(account);
          handleOpenEditDialog();
        }
      } else {
        const accId = id.substr(10);
        const account = items.find((i) => i._id === accId);

        if (account) {
          account.visible = true;
          dispatch(accountActions.updateGroupAccounts([account]));
        }
      }
    },
    [items, dispatch, handleOpenEditDialog],
  );

  const onSortEnd = useCallback(() => {
    const newArray = items.map((item, index) => ({
      ...item,
      orderIndex: index,
    }));

    dispatch(accountActions.updateGroupAccounts(newArray));
  }, [items, dispatch]);

  const handleRemoveIntegration = useCallback(
    (id: string, removeAll: boolean) => {
      dispatch(integrationActions.removeIntegration(id, removeAll));
    },
    [dispatch],
  );

  const handleReconnectAuth = useCallback(
    (id: string, account?: AccountValue) => {
      if (account) {
        handleRemoveIntegration(id, false);

        setIsReconnect(true);
        setCurrentAccount(account);
        setShowIntegrationsDialog(true);
      }
    },
    [handleRemoveIntegration],
  );

  useEffect(() => {
    setItems(accounts);

    if (currentAccount) {
      const acc = accounts.find((el) => el._id === currentAccount._id);

      if (acc) {
        setCurrentAccount(acc);
      }
    }
  }, [accounts, currentAccount]);

  useEffect(() => {
    const inProgress = accounts.find(
      (acc) => !acc.integration?.loaderDone && acc.integration?.loaderState,
    );

    if (inProgress) {
      timer = setTimeout(() => {
        dispatch(accountActions.getSettingsAccounts());
      }, 1000);
    }
  }, [dispatch, accounts]);

  useEffect(() => {
    dispatch(accountActions.getSettingsAccounts());
  }, [dispatch]);

  useEffect(() => {
    if (prevEditInProgressState.current && !editInProgress) {
      setCurrentAccount(null);
      handleCloseEditDialog();
    }

    prevEditInProgressState.current = editInProgress;
  }, [editInProgress, handleCloseEditDialog]);

  return (
    <>
      {!useOnlyNordigenService && (
        <SaltedgeAccountsDialog onCloseAccountsListDialog={handleClose} />
      )}
      <NordigenAccountsDialog onCloseAccountsListDialog={handleClose} />
      <BelvoAccountsDialog onCloseAccountsListDialog={handleClose} />
      <PosterAccountsDialog />
      <Dialog
        title={t('settingsPage.accounts.title')}
        isOpened={isOpened}
        onClose={handleClose}
        className={classes.dialog}
      >
        <>
          {isEditableAccounts && (
            <div className={classes.buttonContainer}>
              <CustomButton
                id="cypress-create-account-button"
                className={classes.button}
                action={handleOpenPreviewRegisterDialog}
                title={t('accounts.add')}
              />
              {fromSettings && <ExportItemsToExcel />}
            </div>
          )}
          <VideoHelper page={HelperPages.differenceAccount} />
          <ReactSortableComponent
            // @ts-ignore
            list={items}
            // @ts-ignore
            setList={setItems}
            onEnd={onSortEnd}
            dropBubble={false}
            dragoverBubble={false}
          >
            {items.map((value) => (
              <RenderAccountRow
                value={value}
                key={value._id}
                onReconnectAuth={handleReconnectAuth}
                onEditRow={handleEditRow}
                subscriptionActive={subscriptionActive}
              />
            ))}
          </ReactSortableComponent>
        </>
      </Dialog>
      {showCreateDialog && (
        <CreateDialog
          isReconnect={isReconnect}
          onClose={handleCloseCreateDialog}
        />
      )}
      {showEditDialog && (
        <EditAccountDialog
          accounts={accounts}
          onClose={handleCloseEditDialog}
          onCloseAccountsDialog={handleClose}
          onUpdate={handleUpdate}
          onDelete={handleDelete}
          account={currentAccount}
          loading={editInProgress}
          removeIntegration={handleRemoveIntegration}
        />
      )}
      {showPreviewRegisterDialog && (
        <PreviewRegisterDialog
          onClose={handleClosePreviewRegisterDialog}
          callback={handleOpenCreateDialog}
        />
      )}
      {showReconnectSaltedgeDialog && (
        <ReconnectSuccessDialog onClose={handleCloseReconnectSaltedgeDialog} />
      )}
      {showReconnectErrorDialog && (
        <ReconnectFailedDialog
          onClose={handleCloseReconnectErrorDialog}
          handleReconnectAuth={handleReconnectAuth}
        />
      )}
      {showIntegrationsDialog && (
        <IntegrationDialog
          onClose={handleCloseIntegrationsDialog}
          account={currentAccount!}
        />
      )}
    </>
  );
}

export default React.memo(AccountsDialog);
