import { Typography } from '@material-ui/core';
import { useCallback } from 'react';
import { downloadExcel } from 'react-export-table-to-excel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FEATURES } from '@finmap/core-constants';

import DownloadIcon from '../../../../../assets/images/svg/download-green.svg';
import useUnleash from '../../../../../hooks/useUnleash';
import { selectSettingsAccounts } from '../../../../../store/accounts/selectors';
import {
  selectSettingsConsumptionCategories,
  selectSettingsIncomeCategories,
} from '../../../../../store/categories/selectors';
import {
  selectSettingsBorrowers,
  selectSettingsClients,
  selectSettingsCreditors,
  selectSettingsInvestors,
  selectSettingsOwners,
  selectSettingsStaffMembers,
  selectSettingsSuppliers,
  selectSettingsTaxOrganisations,
} from '../../../../../store/clients/selectors';
import { selectSettingsProjects } from '../../../../../store/projects/selectors';
import { selectSettingsTags } from '../../../../../store/tags/selectors';
import { useStyles } from './styles';

function ExportItemsToExcel() {
  const { t } = useTranslation();
  const classes = useStyles();

  const showDownloadItemsButton = useUnleash(
    FEATURES.SHOW_DOWNLOAD_ITEMS_BUTTON,
  );

  const accounts = useSelector(selectSettingsAccounts);
  const tags = useSelector(selectSettingsTags);
  const projects = useSelector(selectSettingsProjects);
  const clients = useSelector(selectSettingsClients);
  const investors = useSelector(selectSettingsInvestors);
  const borrowers = useSelector(selectSettingsBorrowers);
  const creditors = useSelector(selectSettingsCreditors);
  const owners = useSelector(selectSettingsOwners);
  const staffMembers = useSelector(selectSettingsStaffMembers);
  const suppliers = useSelector(selectSettingsSuppliers);
  const taxOrganisations = useSelector(selectSettingsTaxOrganisations);
  const income = useSelector(selectSettingsIncomeCategories);
  const consumption = useSelector(selectSettingsConsumptionCategories);

  const accountsList = accounts.map((el) => el.name);
  const tagsList = tags.map((el) => el.label);
  const projectsList = projects.map((el) => el.label);
  const clientsList = clients.map((el) => el.label);
  const investorsList = investors.map((el) => el.label);
  const borrowersList = borrowers.map((el) => el.label);
  const creditorsList = creditors.map((el) => el.label);
  const ownersList = owners.map((el) => el.label);
  const staffMembersList = staffMembers.map((el) => el.label);
  const suppliersList = suppliers.map((el) => el.label);
  const taxOrganisationsList = taxOrganisations.map((el) => el.label);
  const incomeList = income.map((el) => el.label);
  const consumptionList = consumption.map((el) => el.label);

  const createBody = useCallback(() => {
    const maxLen = Math.max(
      accountsList.length,
      tagsList.length,
      projectsList.length,
      clientsList.length,
      investorsList.length,
      borrowersList.length,
      creditorsList.length,
      ownersList.length,
      staffMembersList.length,
      suppliersList.length,
      taxOrganisationsList.length,
      incomeList.length,
      consumptionList.length,
    );

    const body: Array<(string | number | boolean)[]> = [];

    for (let l = 0; l < maxLen; l += 1) {
      const row: any[] = [];

      row.push(accountsList.shift() ?? '');
      row.push(tagsList.shift() ?? '');
      row.push(projectsList.shift() ?? '');
      row.push(clientsList.shift() ?? '');
      row.push(borrowersList.shift() ?? '');
      row.push(creditorsList.shift() ?? '');
      row.push(investorsList.shift() ?? '');
      row.push(ownersList.shift() ?? '');
      row.push(staffMembersList.shift() ?? '');
      row.push(suppliersList.shift() ?? '');
      row.push(taxOrganisationsList.shift() ?? '');
      row.push(incomeList.shift() ?? '');
      row.push(consumptionList.shift() ?? '');

      body.push(row);
    }

    return body;
  }, [
    tagsList,
    incomeList,
    ownersList,
    clientsList,
    projectsList,
    accountsList,
    borrowersList,
    creditorsList,
    investorsList,
    suppliersList,
    consumptionList,
    staffMembersList,
    taxOrganisationsList,
  ]);

  const handleDownloadExcel = useCallback(() => {
    const body = createBody();

    const header = [
      t('employees.settingTitles.accounts'),
      t('employees.settingTitles.tags'),
      t('employees.settingTitles.projects'),
      t('employees.settingTitles.clients'),
      t('employees.settingTitles.borrowers'),
      t('employees.settingTitles.creditors'),
      t('employees.settingTitles.investors'),
      t('employees.settingTitles.owners'),
      t('employees.settingTitles.staffMembers'),
      t('employees.settingTitles.suppliers'),
      t('employees.settingTitles.taxOrganisations'),
      t('employees.settingTitles.categories/income'),
      t('employees.settingTitles.categories/consumption'),
    ];

    downloadExcel({
      fileName: 'Users table',
      sheet: 'Users',
      tablePayload: {
        header,
        body,
      },
    });
  }, [createBody, t]);

  if (!showDownloadItemsButton) {
    return null;
  }

  return (
    <div className={classes.downloadContainer} onClick={handleDownloadExcel}>
      <img src={DownloadIcon} alt="download" className={classes.icon} />
      <Typography className={classes.downloadText}>
        {t('common.download')}
      </Typography>
    </div>
  );
}

export default ExportItemsToExcel;
