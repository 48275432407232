import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class ErsteParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'USD',
          isCurCase: [
            [
              0,
              {
                eq: 'Erste&Steiermärkische',
              },
            ],
            [
              28,
              {
                eq: 'Stanje',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Unos',
                    },
                    nextWord: {
                      eq: 'Valuta',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'Stanje',
                    },
                    prevWord: {
                      eq: 'Uplate',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Unos',
                      },
                      nextWord: {
                        eq: 'Valuta',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Registered',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  regexp: {
                    str: '^(?:--?-?|\\+?)\\d{1,3}(?:\\.\\d{3})*,\\d{2}$',
                  },
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Unos', 0],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['Opis transakcije', 2],
              },
              debit: {
                column: ['Isplate', 3],
              },
              credit: {
                column: ['Uplate', 4],
              },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Unos',
                    },
                    nextWord: {
                      eq: 'Valuta',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: '---',
                    },
                    prevWord: {
                      eq: 'Uplate',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Unos',
                      },
                      nextWord: {
                        eq: 'Valuta',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Registered',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  isNum: true,
                  replace: [
                    {
                      from: '.',
                      to: '',
                    },
                    {
                      from: ',',
                      to: '.',
                    },
                  ],
                },
                {
                  in: ',',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Unos', 0],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['Opis transakcije', 2],
              },
              debit: {
                column: ['Isplate', 3],
              },
              credit: {
                column: ['Uplate', 4],
              },
            },
          },
        },
      },
    ],
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'USD',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'Erste&Steiermärkische Bank d.d.',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Unos',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Valuta',
                    },
                  ],
                ],
                count: 1,
              },
              {
                from: [
                  [
                    3,
                    {
                      eq: 'Ukupno',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              credit: {
                column: ['Uplate', 5],
                if: {
                  neq: [
                    {
                      column: ['Uplate', 5],
                    },
                    '-',
                  ],
                },
                replace: [
                  {
                    from: ',',
                    to: '',
                  },
                ],
              },
              debit: {
                column: ['Isplate', 4],
                if: {
                  neq: [
                    {
                      column: ['Isplate', 4],
                    },
                    '-',
                  ],
                },
                replace: [
                  {
                    from: ',',
                    to: '',
                  },
                ],
              },
              date: {
                column: ['Unos', 0],
                dateFormat: 'DD.MM.YYYY.',
              },
              comment: {
                column: ['Opis transakcije', 3],
              },
            },
          },
        },
      },
    ],
  };
}
