import { Typography } from '@material-ui/core';
import moment from 'moment';
import React, { SyntheticEvent, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@finmap/core-constants';

import { Routes } from '../../../constants/routes';
import useUnleash from '../../../hooks/useUnleash';
import nordigenApi from '../../../services/bankIntegrations/nordigen';
import belvoApi from '../../../services/bankIntegrations/belvo';
import saltedgeApi from '../../../services/bankIntegrations/saltedge';
import Storages, { StorageKey } from '../../../services/Storages';
import {
  getLanguage,
  selectCountIntegrations,
} from '../../../store/accounts/selectors';
import { getUserGeoData } from '../../../store/common/selectors';
import { selectCurrentCompany } from '../../../store/company/selectors';
import {
  BelvoBankType,
  BelvoRedirectData,
  IdentificationInfoDto,
  NordigenBankType,
  NordigenCredentials,
  NordigenCredentialsResponse,
  SaltedgeBankType,
  SaltedgeCredentials,
  SaltedgeCredentialsResponse,
} from '../../../store/integrations/types';
import { getTimeOffset } from '../../../utils/dateToUTC';
import { showError } from '../../../utils/showError';
import { isLite } from '../../../utils/subscriptions';
import AccountAndCalendarDialog from './AccountAndCalendarDialog';
import { nordigenBanks } from './constants';
import NordigenInfoDialog from './NordigenInfoDialog';
import SaltedgeInfoDialog from './SaltedgeInfoDialog';
import BelvoCredsDialog from '../BelvoCredsDialog';
import { Props } from './ServiceProviderBanks.types';
import { useStyles } from './styles';
import BelvoInfoDialog from './BelvoInfoDialog';

function ServiceProviderBanks(props: Props) {
  const {
    data,
    countryCode,
    internalAccountId,
    onOpenSubscriptionDialog,
    onSetShowOnboardingTooltip,
  } = props;
  const classes = useStyles();

  const showFacturowniaIntegration = useUnleash(
    FEATURES.SHOW_FACTUROWNIA_INTEGRATION,
  );
  const userGeoData = useSelector(getUserGeoData);

  let bankList =
    countryCode === 'CZ'
      ? data.map((el) =>
          el.name === 'Ceska Sporitelna'
            ? { ...el, name: 'Česká Spořitelna' }
            : el,
        )
      : data;

  const fakturowniaBank = bankList.find((el) => el.name === 'Fakturownia');

  bankList =
    countryCode === 'PL'
      ? data.filter((el) => el.name !== 'Fakturownia')
      : bankList;

  if (fakturowniaBank && showFacturowniaIntegration) {
    bankList = [fakturowniaBank, ...bankList];
  }

  const company = useSelector(selectCurrentCompany);
  const countIntegrations = useSelector(selectCountIntegrations);
  const language = useSelector(getLanguage);
  const { t } = useTranslation();

  const [currentBank, setCurrentBank] = useState<
    SaltedgeBankType | NordigenBankType | BelvoBankType | null
  >(null);
  const [showCalendar, setShowCalendar] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showSaltadgeInfoDialog, setShowSaltedgeInfoDialog] = useState(false);
  const [showNordigenInfoDialog, setShowNordigenInfoDialog] = useState(false);
  const [saltedgeUrl, setSaltedgeUrl] = useState('');
  const [nordigenUrl, setNordigenUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [externalError, setExternalError] = useState('');
  const [sendFromDateProp, setSendFromDateProp] = useState(true);
  const [bankConnectionId, setBankConnectionId] = useState('');

  const [showBelvoCredsDialog, setShowBelvoCredsDialog] = useState(false);
  const [integrationAccName, setIntegrationAccName] = useState<null | string>(
    null,
  );
  const [integrationDate, setIntegrationDate] = useState<null | number>(null);
  const [showBelvoInfoDialog, setShowBelvoInfoDialog] = useState(false);
  const [belvoUrl, setBelvoUrl] = useState('');

  const showINGsaltedge = useUnleash(FEATURES.SHOW_ING_SALTEDGE);
  const useOnlyNordigenService = useUnleash(FEATURES.USE_ONLY_NORDIGEN_SERVICE);

  const useIMGBankForPolandBySaltagde = countryCode === 'PL' && showINGsaltedge;

  const banksList = useMemo(
    () =>
      useIMGBankForPolandBySaltagde
        ? nordigenBanks.filter((el) => el !== 'ING')
        : nordigenBanks,
    [useIMGBankForPolandBySaltagde],
  );

  const handleCloseSaltedgeInfoDialog = useCallback(() => {
    setShowSaltedgeInfoDialog(false);
  }, []);

  const handleCloseNordigenInfoDialog = useCallback(() => {
    setShowNordigenInfoDialog(false);
  }, []);

  const handleCloseBelvoInfoDialog = useCallback(() => {
    setShowBelvoInfoDialog(false);
  }, []);

  const handleCloseCalendar = useCallback(() => {
    setShowCalendar(false);

    if (onSetShowOnboardingTooltip) {
      onSetShowOnboardingTooltip(true);
    }
  }, [onSetShowOnboardingTooltip]);

  const handleSetupSaltedgeBank = useCallback(
    async (event: SyntheticEvent<HTMLDivElement>) => {
      const { bankid } = event.currentTarget.dataset;
      if (isLite(company) && countIntegrations === 2) {
        onOpenSubscriptionDialog();

        return;
      }
      const bank = bankList.find((el) => el.id.toString() === bankid);
      if (bank) {
        if (bank.customHandler && bank.integrationTypeId) {
          bank.customHandler(bank.integrationTypeId);
        } else {
          setCurrentBank(bank);
          setShowCalendar(true);

          if (onSetShowOnboardingTooltip) {
            onSetShowOnboardingTooltip(false);
          }
        }
      }
    },
    [
      company,
      bankList,
      countIntegrations,
      onOpenSubscriptionDialog,
      onSetShowOnboardingTooltip,
    ],
  );

  const startSaltedgeIntegration = useCallback(
    async (accountName: string, date: number) => {
      if (!currentBank) {
        return;
      }

      const payload: SaltedgeCredentials = {
        takingDaysNumber: (currentBank as SaltedgeBankType)
          .max_consent_days as SaltedgeBankType['max_consent_days'],
        redirectURL: window.location.origin,
        providerCode: currentBank.code as SaltedgeBankType['code'],
      };

      if (sendFromDateProp) {
        payload.from_date = date;
      }

      try {
        setLoading(true);

        const { data: saltedgeCredentials } = await saltedgeApi.getCredentials(
          payload,
        );
        const { acceptingLink, integrationId, connectionId } =
          saltedgeCredentials as SaltedgeCredentialsResponse;

        Storages.put(StorageKey.saltedge, {
          integrationId,
          accountName,
          accountId: internalAccountId,
          startDate: date,
          bankName: currentBank.name,
          connectionId,
        });

        setLoading(false);

        if (connectionId) {
          setBankConnectionId(connectionId);
        }

        setSaltedgeUrl(acceptingLink);

        setShowSaltedgeInfoDialog(true);
      } catch (error) {
        // @ts-ignore
        if (error?.response?.saltageError === 'Date is out of consent range.') {
          setSendFromDateProp(false);
        }

        setLoading(false);
        // @ts-ignore
        setErrorMessage(error?.response?.message);
        showError(error);
      }
    },
    [currentBank, internalAccountId, sendFromDateProp],
  );

  const startBelvoIntegration = useCallback(
    async (
      accountName: string,
      date: number,
      identification_info?: IdentificationInfoDto[],
    ) => {
      if (!currentBank) {
        return;
      }

      const payload: BelvoRedirectData = {
        stale_in: `${(currentBank as BelvoBankType).maxTakingDaysNumber}d`,
        fetch_resources: (currentBank as BelvoBankType).resources,
        callback_url: window.location.origin,
        locale: language?.toLocaleLowerCase(),
        institution: (currentBank as BelvoBankType).name_code,
        integration_type: (currentBank as BelvoBankType).integration_type,
        identification_info,
        logoLink: (currentBank as BelvoBankType).logo_url,
      };

      try {
        setLoading(true);
        const { data: belvoCredentials } = await belvoApi.getRedirectLink(
          payload,
        );

        const { integrationId, redirectURL } = belvoCredentials;

        Storages.put(StorageKey.belvo, {
          integrationId,
          accountName,
          accountId: internalAccountId,
          startDate: date,
          bankName: currentBank.name,
        });

        setLoading(false);

        setBelvoUrl(redirectURL);
        setShowBelvoInfoDialog(true);
      } catch (error) {
        setLoading(false);
        showError(error);
        setExternalError(t('bank.loadAccountsError.title'));
      }
    },
    [currentBank, setShowBelvoInfoDialog, language, internalAccountId, t],
  );

  const startIntegration = useCallback(
    async (accountName: string, date: number) => {
      if (!currentBank) {
        return;
      }

      const takingDaysNumber =
        moment
          .utc()
          .endOf('day')
          .diff(moment.utc(date).startOf('day'), 'days') + 1;

      if (
        (banksList.includes(currentBank.name) &&
          !(currentBank as NordigenBankType).logo_url) ||
        useOnlyNordigenService
      ) {
        const payload: NordigenCredentials = {
          takingDaysNumber,
          bankId: currentBank.id as string,
          countryCode,
          redirectURL: window.location.origin,
        };

        try {
          setLoading(true);

          const { data: nordigenCredentials } =
            await nordigenApi.getCredentials(payload);

          const { acceptingLink, integrationId, fromHistory } =
            nordigenCredentials as NordigenCredentialsResponse;

          const { data: accountsData } =
            await nordigenApi.getIntegrationAccounts(integrationId);

          Storages.put(StorageKey.nordigen, {
            integrationId,
            accountName,
            accountId: internalAccountId,
            startDate: date,
            bankName: currentBank.name,
          });

          if (fromHistory) {
            window.location.href = Routes.LOG;
          } else if (accountsData.status) {
            setLoading(false);

            setNordigenUrl(acceptingLink);
            setShowNordigenInfoDialog(true);
          }
        } catch (error) {
          setLoading(false);
          showError(error);
        }
      } else {
        await startSaltedgeIntegration(accountName, date);
      }
    },
    [
      banksList,
      countryCode,
      currentBank,
      internalAccountId,
      useOnlyNordigenService,
      startSaltedgeIntegration,
    ],
  );

  const handleCloseBelvoCredentialsDialod = useCallback(() => {
    setShowBelvoCredsDialog(false);
  }, []);

  const handleSuccessBelvoCredentialsDialod = useCallback(
    (number: string, name: string) => {
      setShowBelvoCredsDialog(false);
      if (integrationAccName && integrationDate) {
        startBelvoIntegration(integrationAccName, integrationDate, [
          { number, name, type: 'CPF' },
        ]);
      }
    },
    [startBelvoIntegration, integrationAccName, integrationDate],
  );

  const onRedirectToSaltedAccount = useCallback(() => {
    if (bankConnectionId) {
      window.location.href = Routes.LOG;

      return;
    }

    window.location.href = saltedgeUrl;
  }, [saltedgeUrl, bankConnectionId]);

  const onRedirectToNordigenAccount = useCallback(() => {
    window.location.href = nordigenUrl;
  }, [nordigenUrl]);

  const onRedirectToBelvoAccount = useCallback(() => {
    window.location.href = belvoUrl;
  }, [belvoUrl]);

  const handleShowInfoDialog = useCallback(
    (accName: string, date: number) => {
      setErrorMessage('');

      if (
        currentBank &&
        !(
          (
            banksList.includes(currentBank?.name) &&
            !(currentBank as NordigenBankType).logo_url
          ) // saltage logo link key (not nordigen)
        )
      ) {
        if (!useOnlyNordigenService) {
          startSaltedgeIntegration(accName, date);
        } else {
          if (currentBank.isBelvo) {
            if (
              (currentBank as BelvoBankType).integration_type !== 'openfinance'
            ) {
              startBelvoIntegration(accName, date);
            } else {
              setIntegrationDate(date);
              setIntegrationAccName(accName);
              setShowBelvoCredsDialog(true);
            }
          } else {
            startIntegration(accName, date);
          }
        }
      } else {
        startIntegration(accName, date);
      }
    },
    [
      banksList,
      currentBank,
      startIntegration,
      useOnlyNordigenService,
      startSaltedgeIntegration,
      startBelvoIntegration,
    ],
  );

  const minSaltedgeCalendarDate = new Date(
    moment
      .utc()
      .subtract(
        (currentBank as SaltedgeBankType | NordigenBankType)?.max_consent_days,
        'days',
      )
      .valueOf() + getTimeOffset(),
  );

  const minNordigenCalendarDate = new Date(
    moment
      .utc()
      .subtract(
        (currentBank as SaltedgeBankType | NordigenBankType)
          ?.maxTakingDaysNumber,
        'days',
      )
      .valueOf() + getTimeOffset(),
  );

  let minDate =
    currentBank && banksList.includes(currentBank.name) && !currentBank.logo_url
      ? minNordigenCalendarDate
      : minSaltedgeCalendarDate;

  if (useOnlyNordigenService) {
    minDate = minNordigenCalendarDate;
  }

  return (
    <>
      {bankList.map((bank) => (
        <div
          key={bank.id}
          className={classes.button}
          id={`${bank.name}_my_accounts`}
          data-bankid={bank.id}
          onClick={handleSetupSaltedgeBank}
        >
          <div className={classes.flex}>
            <img
              alt={`${bank.id}`}
              src={
                userGeoData?.country === 'UA' && !bank?.isBelvo
                  ? `/nordigenIcons/${bank.id}.png`
                  : // @ts-ignore
                    bank.logo_url || bank.logoLink
              }
              className={classes.icon}
            />
            <Typography
              id={`${bank.name}_my_accounts_text`}
              className={classes.bankText}
            >
              {bank.name}
            </Typography>
          </div>
        </div>
      ))}
      {showCalendar && (
        <AccountAndCalendarDialog
          onClose={handleCloseCalendar}
          onChange={handleShowInfoDialog}
          minDate={minDate}
          showCreateAccountRow={!internalAccountId}
          loading={loading}
          externalDateError={errorMessage}
          externalButtonError={externalError}
        />
      )}
      {showSaltadgeInfoDialog && currentBank && (
        <SaltedgeInfoDialog
          onClose={handleCloseSaltedgeInfoDialog}
          onChange={onRedirectToSaltedAccount}
          bank={currentBank as SaltedgeBankType}
          loading={loading}
        />
      )}
      {showNordigenInfoDialog && currentBank && (
        <NordigenInfoDialog
          onClose={handleCloseNordigenInfoDialog}
          onChange={onRedirectToNordigenAccount}
          bank={currentBank as NordigenBankType}
          loading={loading}
        />
      )}
      {showBelvoInfoDialog && currentBank && (
        <BelvoInfoDialog
          onClose={handleCloseBelvoInfoDialog}
          onChange={onRedirectToBelvoAccount}
          bank={currentBank as BelvoBankType}
          loading={loading}
        />
      )}
      {showBelvoCredsDialog && currentBank && (
        <BelvoCredsDialog
          isOpened={showBelvoCredsDialog}
          onClose={handleCloseBelvoCredentialsDialod}
          onSuccess={handleSuccessBelvoCredentialsDialod}
        />
      )}
    </>
  );
}

export default React.memo(ServiceProviderBanks);
