import { MenuItem, MenuList } from '@material-ui/core';
import cn from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FEATURES } from '@finmap/core-constants';

import HistoryIcon from '../../../../assets/images/svg/history-gray.svg';
import ButtonRowWithIcons from '../../../../components/ButtonRowWithIcons';
import PopperComponent from '../../../../components/PopperComponent';
import useUnleash from '../../../../hooks/useUnleash';
import { getLanguage } from '../../../../selectors/main';
import { persianGreen } from '../../../../theme/colors';
import { CustomPeriodEnum } from '../../HOC/types';
import CalendarDialog from '../CalendarComponent';
import CalendarAirbnbVersion from '../CalendarComponent/CalendarAirbnbVersion';
import utils from '../CalendarComponent/utils';
import { useCompareStyles } from '../compareStyles';
import { useStyles } from './styles';
import { Props } from './types';

function CustomPeriod(props: Props) {
  const {
    date,
    minDate,
    maxDate,
    onChange,
    periodId,
    isCompare,
    difference,
    endTimestamp,
    startTimestamp,
    onChangePeriodId,
    prefix = 'customPeriod.per',
  } = props;
  const classes = useStyles();
  const compareClasses = useCompareStyles();
  const { t } = useTranslation();
  const language = useSelector(getLanguage);

  const useCalendarAirbnbVersion = useUnleash(
    FEATURES.USE_CALENDAR_AIRBNB_VERSION,
  );

  const CalendarDialogComponent = useCalendarAirbnbVersion
    ? CalendarAirbnbVersion
    : CalendarDialog;

  const prevMonth = useMemo(
    () => moment.utc(date).locale(language).subtract(1, 'month').format('MMMM'),
    [date, language],
  );

  const currentMonth = useMemo(
    () => moment.utc(date).locale(language).format('MMMM'),
    [date, language],
  );

  const formatTitle = useCallback(() => {
    if (!periodId) {
      return t('customPeriod.title');
    }

    if (periodId === CustomPeriodEnum.currentMonth) {
      return `${t(prefix)} ${currentMonth}`;
    }

    if (periodId === CustomPeriodEnum.prevMonth) {
      return `${t(prefix)} ${prevMonth}`;
    }

    return utils.formatDateRow([startTimestamp, endTimestamp]);
  }, [
    t,
    prefix,
    periodId,
    prevMonth,
    currentMonth,
    endTimestamp,
    startTimestamp,
  ]);

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleOpenCalendar = useCallback(() => {
    setIsOpenCalendar(true);
  }, []);

  const handleCloseCalendar = useCallback(() => {
    setIsOpenCalendar(false);
  }, []);

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleChangePeriodId = useCallback(
    (event: any) => {
      const id = event.currentTarget.id as CustomPeriodEnum;

      if (id !== CustomPeriodEnum.custom) {
        onChangePeriodId(id);
      } else {
        handleOpenCalendar();
      }

      handleClose();
    },
    [onChangePeriodId, handleOpenCalendar, handleClose],
  );

  const handleChangeCalendar = useCallback(
    (data: number[]) => {
      onChange(data);
      onChangePeriodId(CustomPeriodEnum.custom);
      handleCloseCalendar();
    },
    [onChange, onChangePeriodId, handleCloseCalendar],
  );

  const handleToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return (
    <>
      <ButtonRowWithIcons
        onClick={handleToggle}
        icon={HistoryIcon}
        alt="history"
        label={formatTitle()}
        anchorRef={anchorRef}
        disabled={isCompare}
        wrapClasses={difference && compareClasses.root}
      />
      {isOpen && (
        <PopperComponent
          onClose={handleClose}
          anchorRef={anchorRef}
          placement="bottom-start"
          disablePortal
        >
          <MenuList autoFocusItem={isOpen} id="menu-list-grow">
            <MenuItem
              className={cn(
                periodId === CustomPeriodEnum.prevMonth && classes.selected,
              )}
              onClick={handleChangePeriodId}
              id={CustomPeriodEnum.prevMonth}
            >
              <div className={classes.itemRow}>
                {`${t('customPeriod.perLastMonth')} (${prevMonth})`}
                {periodId === CustomPeriodEnum.prevMonth && (
                  <CheckIcon color={persianGreen} />
                )}
              </div>
            </MenuItem>
            <MenuItem
              className={cn(
                periodId === CustomPeriodEnum.currentMonth && classes.selected,
              )}
              id={CustomPeriodEnum.currentMonth}
              onClick={handleChangePeriodId}
            >
              <div className={classes.itemRow}>
                {`${t('customPeriod.perThisMonth')} (${currentMonth})`}
                {periodId === CustomPeriodEnum.currentMonth && (
                  <CheckIcon color={persianGreen} />
                )}
              </div>
            </MenuItem>
            <MenuItem
              className={cn(
                periodId === CustomPeriodEnum.custom && classes.selected,
              )}
              id={CustomPeriodEnum.custom}
              onClick={handleChangePeriodId}
            >
              <div className={classes.itemRow}>
                {t('customPeriod.setPeriod')}
                {periodId === CustomPeriodEnum.custom && (
                  <CheckIcon color={persianGreen} />
                )}
              </div>
            </MenuItem>
          </MenuList>
        </PopperComponent>
      )}
      {isOpenCalendar && (
        <CalendarDialogComponent
          value={null}
          airbnbValue={[startTimestamp, endTimestamp]}
          onClose={handleCloseCalendar}
          onChange={handleChangeCalendar}
          isSelectRange
          minDate={minDate}
          maxDate={maxDate}
        />
      )}
    </>
  );
}

export default React.memo(CustomPeriod);
