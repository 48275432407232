import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
  ImportResultItemMask,
} from '@finmap/import-parsers/base-import-parser-v3';

export class CreditAgricoleImportParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { in: 'Період:' }]],
          preParserConfigs: {
            separatorsDistanceModifier: 0,
            maxInterlineSpacing: 20,
            interlineSpacingAccuracy: 7,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' } }],
                to: [{ word: { in: 'Кредит' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Кредит' } }],
                  count: 1,
                },
              ],
              define: [{ dateFormat: 'DD.MM.YYYY' }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата\nоперації', 0],
                replaceOne: [{ from: { regexp: { str: '\\s' } }, to: ' ' }],
              },
              counterparty: {
                column: ['Контрагент\nРахунок', 3],
                split: { by: '\n', get: 1 },
              },
              debit: { column: ['Дебет', 6] },
              credit: { column: ['Кредит', 7] },
              comment: { column: ['Призначення платежу', 8] },
            },
          },
        },
      },
      // case 2
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: {
            and: [
              [
                [
                  0,
                  {
                    in: 'Цей документ не підлягає будь-якому додатковому підписанню',
                  },
                ],
              ],
              [[0, { in: 'Код:' }]],
            ],
          },
          preParserConfigs: {
            pageSeparatorsLengthPx: 13,
            separatorsDistanceModifier: 0.67,
            interlineSpacingAccuracy: 7,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' } }],
                to: [{ word: { in: 'Кредит' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Кредит' } }],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        startsWith:
                          'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                      },
                    },
                  ],
                  to: [{ word: { in: 'Кредит' } }],
                },
                {
                  from: [
                    {
                      word: {
                        startsWith:
                          'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                      },
                    },
                  ],
                },
              ],
              define: [{ regexp: { str: '^([,\\d]+\\.\\d{2})$' } }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата\nоперації', 0],
                replaceOne: [{ from: { regexp: { str: '\\s' } }, to: ' ' }],
              },
              counterparty: {
                column: ['Рахунок', 3],
                split: { by: '\n', get: 0 },
              },
              debit: { column: ['Дебет', 5] },
              credit: { column: ['Кредит', 6] },
              comment: { column: ['Призначення платежу', 7] },
            },
          },
        },
      },
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [[0, { in: 'Директор з Проксіміті банкінгу та мережі' }]],
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            separatorsDistanceModifier: 0.7,
            interlineSpacingAccuracy: 40,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' } }],
                to: [{ word: { in: 'Кредит' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Кредит' } }],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        startsWith:
                          'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                      },
                    },
                  ],
                  to: [{ word: { in: 'Кредит' } }],
                },
              ],
              define: [{ regexp: { str: '^([,\\d]+\\.\\d{2})$' } }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата\nоперації', 0],
                replaceOne: [{ from: { regexp: { str: '\\s' } }, to: ' ' }],
              },
              counterparty: {
                column: ['Рахунок', 3],
                split: { by: '\n', get: 0 },
              },
              debit: { column: ['Дебет', 5] },
              credit: { column: ['Кредит', 6] },
              comment: { column: ['Призначення платежу', 7] },
            },
          },
        },
      },
      // case 9
      {
        proceedCase: (importDocument) => {
          const header = importDocument[0];
          const body = [];
          importDocument.slice(1).forEach((el, i) => {
            if (i === 0) {
              body.push(el);
              return;
            }
            const prevComments = body[i - 1][2].split('\n');
            const currentComments = el[2].split('\n');
            if (prevComments.length && currentComments.length) {
              if (
                prevComments[prevComments.length - 1] === currentComments[0]
              ) {
                currentComments.shift();
                el[2] = currentComments.join('\n');
              }
            }
            body.push(el);
          });
          this.setDocumentHeader(header);
          this.setDocumentBody(body);
          return (): ImportResultItemMask => ({
            date: this.getFirstValidCellByColumn(['Дата операції', 0]),
            currency: this.getFirstValidCellByColumn(['Валюта\nоперації', 3]),
            sum: this.getFirstValidCellByColumn(['Сума в\nвалюті\nрахунка', 6]),
            comment: this.getFirstValidCellByColumn(['Опис операції', 2]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: {
            simple: [[0, { eq: 'АТ «КРЕДІ АГРІКОЛЬ БАНК»' }]],
          },
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            separatorsDistanceModifier: 0.2,
            verticalAlign: 'middle',
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Сума в' }, nextWord: { eq: 'Сума в' } }],
                to: [{ word: { in: 'рахунка' }, prevWord: { eq: 'операції' } }],
              },
              delete: [
                {
                  from: [{ word: { eq: 'АТ «КРЕДІ АГРІКОЛЬ БАНК»' } }],
                  to: [
                    { word: { in: ' (міжнародні)' } },
                    { word: { in: ' (по Україні), ' } },
                  ],
                },
                {
                  from: [{ nextWord: { eq: 'Дата формування:' } }],
                  to: [
                    {
                      word: {
                        isNum: true,
                        replaceOne: [{ from: ',', to: '.' }],
                      },
                      prevWord: { eq: 'Незнижувальний залишок:' },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [{ regexp: { str: '^-?\\d+(,\\d{2})$' } }],
            },
          },
        },
      },

      {
        caseOptions: {
          defaultCurrency: 'UAH',
          isCurCase: [
            [
              0,
              {
                in: 'Виписка по картковому рахунку за період',
              },
            ],
          ],
          preParserConfigs: {
            verticalAlign: 'middle',
            maxInterlineSpacingPx: 9,
            interlineSpacingAccuracy: 1,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'Дата',
                    },
                    nexWord: {
                      eq: 'No',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'рахунком',
                    },
                    prevWord: {
                      eq: 'рахунку',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'Дата',
                      },
                      nexWord: {
                        eq: 'No',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Всього по внутрішнім операціям:',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'DD.MM.YYYY',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Дата\nпроведення за\nрахунком', 2],
              },
              sum: {
                column: ['Сума у валюті\nрахунку', 7],
              },
              comment: {
                column: ['Опис операції', 4],
              },
            },
          },
        },
      },

      {
        proceedCase: (importDocument) => {
          this.setDocumentHeader(importDocument[0]);
          this.setDocumentBody(importDocument.slice(1));
          return (): ImportResultItemMask => ({
            dateAndTime: this.getFirstValidCellByColumn([
              'Дата\nоперації',
              0,
            ])?.replace(/\s/, ' '),
            counterparty: this.getFirstValidCellByColumn(['Рахунок', 3])
              ?.replace(/[A-Z]{2}[\d\s]+\n/g, '')
              .split('\n')[0],
            debit:
              this.getFirstValidCellByColumn(['Дебет']) ||
              this.getFirstValidCellByColumn(['Сума']),
            credit: this.getFirstValidCellByColumn(['Кредит']),
            comment: this.getFirstValidCellByColumn(['Призначення платежу', 7]),
          });
        },
        caseOptions: {
          defaultCurrency: 'UAH',
          preParserConfigs: {
            pageSeparatorsLengthPx: 3,
            separatorsDistanceModifier: 0.83,
            interlineSpacingAccuracy: 40,
            prepareRawConfig: {
              findHeader: {
                from: [{ word: { eq: 'Дата' } }],
                to: [{ word: { in: 'Кредит' } }],
              },
              delete: [
                {
                  to: [{ word: { in: 'Кредит' } }],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        startsWith:
                          'Акціонерне товариство «КРЕДІ АГРІКОЛЬ БАНК» - вул. Пушкінська',
                      },
                    },
                  ],
                  to: [{ word: { in: 'Кредит' } }],
                },
              ],
              define: [{ regexp: { str: '^([,\\d]+\\.\\d{2})$' } }],
            },
          },
          proceedCaseConfig: {
            fields: {
              dateAndTime: {
                column: ['Дата\nоперації', 0],
                replaceOne: [{ from: { regexp: { str: '\\s' } }, to: ' ' }],
              },
              counterparty: {
                column: ['Рахунок', 3],
                replaceOne: [
                  {
                    from: {
                      regexp: { str: '[A-Z]{2}[\\d\\s]+\\n', flags: 'g' },
                    },
                    to: '',
                  },
                ],
                split: { by: '\n', get: 0 },
              },
              debit: { or: [{ column: ['Дебет'] }, { column: ['Сума'] }] },
              credit: { column: ['Кредит'] },
              comment: { column: ['Призначення платежу', 7] },
            },
          },
        },
      },
    ],
  };
}
