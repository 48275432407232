import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class MonoBusinessParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLSX]: [
      {
        caseOptions: {
          defaultCurrency: 'UAH',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                in: 'Список транзакцій за рахунком',
              },
            ],
          ],
          proceedCaseConfig: {
            withoutEmpty: true,
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Дата \nоперації',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Час \nоперації',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Сума в валюті \nрахунку', 7],
              },
              date: {
                column: ['Дата \nоперації', 0],
              },
              time: {
                column: ['Час \nоперації', 1],
              },
              comment: {
                column: ['Деталі операції', 3],
              },
              counterparty: {
                column: ['Контрагент', 4],
              },
            },
          },
        },
      },
    ],
  };
}
