import { useMemo } from 'react';
import { FEATURES } from '@finmap/core-constants';

import useUnleash from './useUnleash';

function useNordigenIntegration() {
  const showBankMillenniumIntegration = useUnleash(
    FEATURES.SHOW_BANK_MILLENNIUM_INTEGRATION,
  );
  const showBankEburyIntegration = useUnleash(
    FEATURES.SHOW_BANK_EBURY_INTEGRATION,
  );
  const showBankINGIntegration = useUnleash(FEATURES.SHOW_BANK_ING_INTEGRATION);
  const showBankInteligoIntegration = useUnleash(
    FEATURES.SHOW_BANK_INTELIGO_INTEGRATION,
  );
  const showNestBankCorporateIntegration = useUnleash(
    FEATURES.SHOW_NEST_BANK_CORPORATE_INTEGRATION,
  );
  const showNestBankPrivateIntegration = useUnleash(
    FEATURES.SHOW_NEST_BANK_PRIVATE_INTEGRATION,
  );
  const showSantanderBankIntegration = useUnleash(
    FEATURES.SHOW_SANTANDER_BANK_INTEGRATION,
  );
  const showSantanderBankCorporateIntegration = useUnleash(
    FEATURES.SHOW_SANTANDER_BANK_CORPORATE_INTEGRATION,
  );
  const showINGsaltedge = useUnleash(FEATURES.SHOW_ING_SALTEDGE);

  return useMemo(
    () => [
      {
        name: 'Bank Millennium',
        enabled: showBankMillenniumIntegration,
      },
      {
        name: 'Ebury',
        enabled: showBankEburyIntegration,
      },
      {
        name: 'ING',
        enabled: showBankINGIntegration && !showINGsaltedge,
      },
      {
        name: 'Inteligo',
        enabled: showBankInteligoIntegration,
      },
      {
        name: 'Nest Bank Corporate',
        enabled: showNestBankCorporateIntegration,
      },
      {
        name: 'Nest Bank Private',
        enabled: showNestBankPrivateIntegration,
      },
      {
        name: 'Santander Bank',
        enabled: showSantanderBankIntegration,
      },
      {
        name: 'Santander Bank Corporate',
        enabled: showSantanderBankCorporateIntegration,
      },
    ],
    [
      showINGsaltedge,
      showBankINGIntegration,
      showBankEburyIntegration,
      showBankInteligoIntegration,
      showSantanderBankIntegration,
      showBankMillenniumIntegration,
      showNestBankPrivateIntegration,
      showNestBankCorporateIntegration,
      showSantanderBankCorporateIntegration,
    ],
  );
}

export default useNordigenIntegration;
