import React from 'react';
import { FEATURES } from '@finmap/core-constants';

import useUnleash from '../../../../hooks/useUnleash';
import CalendarAirbnbVersion from './CalendarAirbnbVersion';
import CalendarDialog from './CalendarDialog';
import { Props } from './CalendarDialog.types';

function CalendarContainer(props: Props) {
  const useCalendarAirbnbVersion = useUnleash(
    FEATURES.USE_CALENDAR_AIRBNB_VERSION,
  );

  return useCalendarAirbnbVersion ? (
    <CalendarAirbnbVersion {...props} />
  ) : (
    <CalendarDialog {...props} />
  );
}

export default React.memo(CalendarContainer);
