import { useSelector } from 'react-redux';
import { FEATURES } from '@finmap/core-constants';

import { ImportFrom, selectImportsList } from '../store/import/selectors';
import { ImportType } from '../store/import/types';
import useUnleash from './useUnleash';

type ImportProps = {
  id: ImportType;
  icon: string;
  title: string;
  dataAttribute: string;
  type: ImportFrom;
};

function useImports() {
  const importsList: ImportProps[] = useSelector(selectImportsList);
  const showFintabloImport = useUnleash(FEATURES.SHOW_FINTABLO_IMPORT);
  const show1CImport = useUnleash(FEATURES.SHOW_1C_IMPORT);

  return importsList.reduce((acc: ImportProps[], el: ImportProps) => {
    const { id } = el;

    if (id === ImportType.fintablo && !showFintabloImport) {
      return acc;
    }
    if (id === ImportType.kaspiBusiness) {
      return acc;
    }
    if (id === ImportType.kaspiPrivate) {
      return acc;
    }
    if (id === ImportType['1c'] && !show1CImport) return acc;

    acc.push(el);

    return acc;
  }, []);
}

export default useImports;
