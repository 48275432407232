import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class DemirParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.XLS]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          withoutEmpty: true,
          isCurCase: [
            [
              0,
              {
                eq: 'DEAR',
              },
            ],
          ],
          proceedCaseConfig: {
            delete: [
              {
                to: [
                  [
                    0,
                    {
                      eq: 'Bank Date',
                    },
                  ],
                  [
                    1,
                    {
                      eq: 'Payment Number',
                    },
                  ],
                ],
                count: 1,
              },
            ],
            fields: {
              sum: {
                column: ['Transaction Amount', 8],
              },
              date: {
                column: ['Bank Date', 0],
                dateFormat: 'YYYYMMDD',
              },
              comment: {
                column: ['Purpose of payment', 7],
              },
            },
          },
        },
      },
    ],
  };
}
