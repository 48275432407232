import { Typography } from '@material-ui/core';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { FEATURES } from '@finmap/core-constants';

import BannerCZ from '../../../../../assets/images/referral/banner_referal_cz.png';
import BannerEN from '../../../../../assets/images/referral/banner_referal_en.png';
import BannerPL from '../../../../../assets/images/referral/banner_referal_pl.png';
import BannerRU from '../../../../../assets/images/referral/banner_referal_ru.png';
import BannerSP from '../../../../../assets/images/referral/banner_referal_sp.png';
import BannerTR from '../../../../../assets/images/referral/banner_referal_tr.png';
import CustomButton from '../../../../../components/Button';
import Dialog from '../../../../../components/Dialog/Dialog';
import TextFieldComponent from '../../../../../components/TextField/TextFieldComponent';
import { REFERRAL_LINK } from '../../../../../constants';
import useUnleash from '../../../../../hooks/useUnleash';
import { getLanguage } from '../../../../../selectors/main';
import { getUser } from '../../../../../store/auth/selectors';
import { selectCurrentCompany } from '../../../../../store/company/selectors';
import { Participant } from '../../../../../typings';
import RenderUK from './RenderUK';
import { useStyles } from './styles';

const bannerToLanguage: { [key in string]: string } = {
  en: BannerEN,
  ru: BannerRU,
  pl: BannerPL,
  tr: BannerTR,
  sp: BannerSP,
  cz: BannerCZ,
};

function ReferralDialog({ onClose }: { onClose(): void }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const user = useSelector(getUser);
  const company = useSelector(selectCurrentCompany);
  const language = useSelector(getLanguage);

  const showDetailsReferralLink = useUnleash(
    FEATURES.SHOW_DETAILS_REFERRAL_LINK,
  );

  const [participant, setParticipant] = useState<Participant | null>(null);

  const onCopyLink = useCallback(() => {
    copy(participant?.shareUrl ?? '');
  }, [participant]);

  useEffect(() => {
    const getParticipant = async () => {
      const data = await window.growsurf.getParticipantByEmail(
        user?.email || '',
      );

      if (data) {
        setParticipant(data);
      } else {
        const newData = await window.growsurf.addParticipant({
          email: user?.email || '',
          company: company?.name,
        });

        if (newData) {
          setParticipant(newData);
        }
      }
    };

    if (window.growsurf) {
      getParticipant();
    }
  }, [user, company]);

  return (
    <Dialog isOpened onClose={onClose} title="">
      <>
        {language !== 'uk' && (
          <div className={classes.logoContainer}>
            <img
              src={bannerToLanguage[language]}
              alt="title"
              className={classes.logo}
            />
          </div>
        )}
      </>
      <>
        {language === 'uk' && <RenderUK />}
        {language !== 'uk' && (
          <>
            <Typography
              className={cn(classes.text, classes.bold, classes.marginBottom)}
            >
              {t('userMenu.referralProgram.condition')}
            </Typography>
            <ol className={classes.list}>
              <li>
                <Typography className={cn(classes.text, classes.marginBottom)}>
                  {t('userMenu.referralProgram.description1')}
                </Typography>
              </li>
              <li>
                <Typography className={cn(classes.text, classes.marginBottom)}>
                  {t('userMenu.referralProgram.description2')}
                </Typography>
              </li>
              <li>
                <Typography className={cn(classes.text, classes.marginBottom)}>
                  {t('userMenu.referralProgram.description3')}
                </Typography>
              </li>
            </ol>
            <Typography className={cn(classes.text, classes.marginBottom)}>
              {t('userMenu.referralProgram.description4')}
            </Typography>
          </>
        )}
      </>

      <>
        {showDetailsReferralLink && (
          <a
            target="_blank"
            rel="noreferrer"
            href={REFERRAL_LINK}
            className={cn(classes.text, classes.marginBottom, classes.link)}
          >
            {t('userMenu.referralProgram.details')}
          </a>
        )}
      </>

      <TextFieldComponent
        readableOnly
        label={t('userMenu.referralProgram.uniqueLink')}
        placeholder={t('userMenu.referralProgram.uniqueLink')}
        fullWidth
        value={participant ? participant.shareUrl : ''}
      />
      <CustomButton
        title={t('userMenu.referralProgram.copyLink')}
        action={onCopyLink}
        fullWidth
      />
    </Dialog>
  );
}

export default React.memo(ReferralDialog);
