import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class TrusteeParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'EUR',
          preParserConfigs: {
            separatorsDistanceModifier: 0.7,
            interlineSpacingAccuracy: 10,
            verticalAlign: 'top',
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      in: 'Date of',
                    },
                    nexWord: {
                      eq: 'Balance after',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'transaction',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        in: 'Date of',
                      },
                      nexWord: {
                        eq: 'Balance after',
                      },
                    },
                  ],
                  count: 1,
                },
              ],
              define: [
                {
                  dateFormat: 'YYYY.MM.DD',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['Date of operation/\nPosting date', 0],
                dateFormat: 'YYYY.MM.DD',
              },
              comment: {
                column: ['Transaction title', 1],
              },
              sum: {
                column: ['Sum', 2],
              },
            },
          },
        },
      },
    ],
  };
}
