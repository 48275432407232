import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FEATURES } from '@finmap/core-constants';

import FacebookPixelContext from '../../../../Providers/FacebookFixelProvider/FacebookPixelContext';
import operationActions from '../../../../store/operations/actions';
import { getOperationProps } from '../../../../store/operations/selectors';
import {
  CreateCancellationSupplierResponseData,
  OperationSubType,
  OperationType,
} from '../../../../store/operations/types';
import { SupplierProps } from './types';
import useMainPayload from './useMainPayload';
import useUnleash from '../../../../hooks/useUnleash';

function useCreateSupplier(props: SupplierProps) {
  const {
    client,
    category,
    isEdit,
    isCopy,
    editProps,
    scheduled,
    shouldCreate,
    onCloseDialog,
    resetShouldCreate,
    shouldCloseDialog,
    fixOffsetBug,
  } = props;
  const facebookContext = useContext(FacebookPixelContext);
  const isLongLoad = useUnleash(FEATURES.LONG_UPDATE_OPERATION);

  const { account, isCopyComponent, editOperationId } =
    useSelector(getOperationProps);

  const dispatch = useDispatch();
  const location = useLocation();

  const mainPayloadProps = useMainPayload({ editProps, isEdit });

  useEffect(() => {
    if (shouldCreate) {
      // @ts-ignore
      const payload: Omit<CreateCancellationSupplierResponseData, 'companyId'> =
        {
          ...mainPayloadProps,
          scheduled,
          accountFromId: account ? account._id : undefined,
          categoryId: category ? category._id : null,
          supplierId: client ? client._id : null,
        };

      if (isEdit && editProps && editOperationId && !isCopyComponent) {
        dispatch(
          operationActions.updateOperation({
            id: editOperationId,
            // @ts-ignore
            payload: {
              type: OperationType.consumption,
              subType: OperationSubType.supplier,
              ...payload,
            },
            location,
            fixOffsetBug,
            isLongLoad,
          }),
        );
      } else {
        const createPayload = {
          props: {
            ...payload,
            type: OperationType.consumption,
            subType: OperationSubType.supplier,
            isCopy,
          },
          location,
          facebookContext,
          fixOffsetBug,
        };
        dispatch(operationActions.createOperation(createPayload));
      }

      if (shouldCloseDialog) {
        onCloseDialog();
      } else {
        resetShouldCreate();
      }
    }
  }, [
    props,
    isEdit,
    isCopy,
    client,
    account,
    dispatch,
    location,
    category,
    scheduled,
    editProps,
    shouldCreate,
    onCloseDialog,
    editOperationId,
    facebookContext,
    isCopyComponent,
    mainPayloadProps,
    shouldCloseDialog,
    resetShouldCreate,
    fixOffsetBug,
    isLongLoad,
  ]);

  return null;
}

export default useCreateSupplier;
