import { AxiosResponse } from 'axios';
import cn from 'classnames';
import moment from 'moment';
import React, {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { normalize } from '@finmap/core-utils';
import { FEATURES } from '@finmap/core-constants';

import FacturowniaLink from '../../../assets/images/svg/payments/facturownia.svg';
import iDokladLink from '../../../assets/images/svg/payments/iDoklad.png';
import PayoneerLink from '../../../assets/images/svg/payments/payoneer.svg';
import PosterLink from '../../../assets/images/svg/payments/poster.png';
import AutocompleteSelector from '../../../components/AutocompleteSelector';
import Dialog from '../../../components/Dialog/Dialog';
import Loader from '../../../components/Loader/LottieLoader';
import TextFieldComponent from '../../../components/TextField/TextFieldComponent';
import {
  PAYPAL_UNIVERSAL_CODE,
  PAYPAL_UNIVERSAL_NAME,
  WISE_UNIVERSAL_CODE,
  WISE_UNIVERSAL_NAME,
} from '../../../constants';
import useNordigenIntegration from '../../../hooks/useNordigenIntegration';
import useUnleash from '../../../hooks/useUnleash';
import nordigenApi from '../../../services/bankIntegrations/nordigen';
import belvoApi from '../../../services/bankIntegrations/belvo';
import saltedgeApi from '../../../services/bankIntegrations/saltedge';
import { selectCountriesAutocomplete } from '../../../store/integrations/selectors';
import {
  BelvoBankType,
  IntegrationTypeId,
  NordigenBankType,
  SaltedgeBankType,
} from '../../../store/integrations/types';
import { minOperationDate } from '../../../utils/dateFormat';
import { sortByName } from '../../../utils/strings';
import AdditionalBanks from './AdditionalBanks';
import { STRIPE_BANK_NAME } from './constants';
import { Props } from './CountriesDialog.types';
import {
  defaultNordigenBanks,
  paypalNordigenBank,
  revoluteNordigenBank,
  stripeNordigenBank,
  wiseNordigenBank,
} from './defaultNordigenBanks';
import { defaultSaltedgeBanks } from './defaultSaltedgeBanks';
import ServiceProviderBanks from './ServiceProviderBanks';
import { useStyles } from './styles';
import { testNordigenBanks } from './testNordigenBanks';
import { testSaltedgeBanks } from './testSaltedgeBanks';
import { CountryProps } from './types';
import UkraineBanks from './UkraineBanks';
import { universalBanks } from './universalBanks';

let timeout: NodeJS.Timeout | null = null;

const universalBankList = [
  'Fondy',
  'Revolut',
  WISE_UNIVERSAL_NAME,
  PAYPAL_UNIVERSAL_NAME,
];

export const belvoCountries = ['MX', 'CO', 'BR'];

function CountriesDialog(props: Props) {
  const {
    onClose,
    showFondy,
    onSetupBank,
    onSetupUrkBank,
    selectedAccountId,
    currentCountryCode,
    onOpenSubscriptionDialog,
    onCloseIntegrationsDialog,
  } = props;

  const [bankList, setBankList] = useState<
    (SaltedgeBankType | NordigenBankType | BelvoBankType)[]
  >([]);
  const [loading, setLoading] = useState(false);
  const [currentCountry, setCurrentCountry] = useState<CountryProps | null>(
    null,
  );
  const [filteredBanks, setFilteredBanks] = useState<
    (SaltedgeBankType | NordigenBankType | BelvoBankType)[]
  >([]);
  const [searchValue, setSearchValue] = useState<string | null>(null);

  const classes = useStyles();
  const { t } = useTranslation();

  const data = useSelector(selectCountriesAutocomplete);

  const polandBanks = useNordigenIntegration();
  const showStripeBankIntegration = useUnleash(
    FEATURES.SHOW_STRIPE_BANK_INTEGRATION,
  );
  const showINGsaltedge = useUnleash(FEATURES.SHOW_ING_SALTEDGE);
  const showTestSaltedgeBanks = useUnleash(FEATURES.SHOW_TEST_SALTEDGE_BANKS);
  const showDemoNordigenBankOnStage = useUnleash(
    FEATURES.SHOW_DEMO_NORDIGEN_BANK_ON_STAGE,
  );
  const useOnlyNordigenService = useUnleash(FEATURES.USE_ONLY_NORDIGEN_SERVICE);
  const showFacturowniaIntegration = useUnleash(
    FEATURES.SHOW_FACTUROWNIA_INTEGRATION,
  );

  let banksList: NordigenBankType[] | SaltedgeBankType[] = useMemo(
    () =>
      showTestSaltedgeBanks
        ? defaultSaltedgeBanks.concat(testSaltedgeBanks)
        : defaultSaltedgeBanks,
    [showTestSaltedgeBanks],
  );

  const nordigenBanksList: NordigenBankType[] = useMemo(
    () =>
      showDemoNordigenBankOnStage
        ? defaultNordigenBanks.concat(testNordigenBanks)
        : defaultNordigenBanks,
    [showDemoNordigenBankOnStage],
  );

  if (useOnlyNordigenService) {
    banksList = nordigenBanksList;
  }

  const handleSetCurrentCountry = useCallback((country: CountryProps) => {
    setCurrentCountry(country);

    if (country?.alpha2Code) {
      setLoading(true);
    }

    setBankList([]);
  }, []);

  const handleSetupUkrBank = useCallback(
    (event: SyntheticEvent) => {
      onSetupUrkBank(event);
    },
    [onSetupUrkBank],
  );

  const handleSearch = useCallback(
    (value: string) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setSearchValue(value);

      timeout = setTimeout(() => {
        if (value) {
          const filtered = bankList.filter(
            (el) => normalize(el.name).indexOf(normalize(value)) !== -1,
          );

          setFilteredBanks(filtered);
        } else {
          setFilteredBanks(bankList);
        }
      }, 500);
    },
    [bankList],
  );

  useEffect(() => {
    const getBanksList = async (country: CountryProps) => {
      try {
        let allBanks:
          | SaltedgeBankType[]
          | NordigenBankType[]
          | BelvoBankType[] = [];
        if (belvoCountries.includes(country.alpha2Code)) {
          const { data: apiData }: AxiosResponse<BelvoBankType[]> =
            await belvoApi.getAllBanks(country.alpha2Code);
          allBanks = apiData.map((el) => ({ ...el, isBelvo: true }));
        } else {
          const {
            data: apiData,
          }: AxiosResponse<SaltedgeBankType[] | NordigenBankType[]> =
            useOnlyNordigenService
              ? await nordigenApi.getAllBanks(country.alpha2Code)
              : await saltedgeApi.getAllBanks(country.alpha2Code);
          allBanks = apiData;
        }

        const banks = allBanks
          .map((el) => {
            if (el.name === WISE_UNIVERSAL_NAME) {
              return { ...el, code: WISE_UNIVERSAL_CODE };
            }

            if (el.name === PAYPAL_UNIVERSAL_NAME) {
              return { ...el, code: PAYPAL_UNIVERSAL_CODE };
            }

            return el;
          })
          .filter((el) => {
            return !(
              country.alpha2Code === 'PL' && el.code === 'ing_oauth_client_pl'
            );
          });

        let universal = [...banks];

        if (
          !useOnlyNordigenService ||
          belvoCountries.includes(country.alpha2Code)
        ) {
          universal = banks.filter(
            (el: SaltedgeBankType | NordigenBankType | BelvoBankType) =>
              !universalBankList.includes(el.name),
          );
        }

        if (country.alpha2Code !== 'UZ') {
          universal.push(universalBanks.Fondy);
        }

        if (
          !useOnlyNordigenService ||
          belvoCountries.includes(country.alpha2Code)
        ) {
          universal.push(universalBanks[PAYPAL_UNIVERSAL_NAME]);
          universal.push(universalBanks.Revolut);
          universal.push(universalBanks[WISE_UNIVERSAL_NAME]);
        }

        const filteredPolandBanks: NordigenBankType[] = [];

        let stripeBank: ConcatArray<SaltedgeBankType> | NordigenBankType[] = [];

        if (
          !useOnlyNordigenService ||
          belvoCountries.includes(country.alpha2Code)
        ) {
          const { data: allNordigenBanks }: AxiosResponse<NordigenBankType[]> =
            await nordigenApi.getAllBanks(country.alpha2Code);

          stripeBank = showStripeBankIntegration
            ? allNordigenBanks.filter((el) => el.name === STRIPE_BANK_NAME)
            : [];

          if (country.alpha2Code === 'PL') {
            const { data: nordigenBanks }: AxiosResponse<NordigenBankType[]> =
              await nordigenApi.getAllBanks('PL');

            nordigenBanks.forEach((el) => {
              const bank = polandBanks.find(
                (plBank) => el.name === plBank.name,
              );

              if (bank && bank.enabled) {
                filteredPolandBanks.push(el);
              }
            });
          }
        }

        return (
          universal
            // @ts-ignore
            .concat(filteredPolandBanks)
            // @ts-ignore
            .concat(stripeBank)
            .concat(showTestSaltedgeBanks ? testSaltedgeBanks : [])
        );
      } catch (e) {
        return [];
      }
    };

    if (currentCountry && currentCountry.alpha2Code !== 'UA') {
      const currentCountryBanksList = getBanksList(currentCountry);

      const maxTakingDaysNumber = moment.utc().diff(minOperationDate(), 'days');

      currentCountryBanksList.then((list) => {
        if (
          currentCountry.alpha2Code === 'IN' ||
          currentCountry.alpha2Code === 'ZA'
        ) {
          // @ts-ignore
          list.push(stripeNordigenBank);
          list.push(wiseNordigenBank);
          list.push(paypalNordigenBank);
        }

        if (currentCountry.alpha2Code === 'UZ') {
          // @ts-ignore
          list.push(revoluteNordigenBank);
          list.push(paypalNordigenBank);
          list.push(wiseNordigenBank);
          list.push(stripeNordigenBank);
          // @ts-ignore
          list.push({
            name: 'Poster',
            id: 'Poster',
            logo_url: PosterLink,
            customHandler: onSetupBank,
            maxTakingDaysNumber,
            max_consent_days: maxTakingDaysNumber,
          });
        }

        if (currentCountry.alpha2Code === 'KZ') {
          // @ts-ignore
          list.push(revoluteNordigenBank);
          list.push(paypalNordigenBank);
          list.push(wiseNordigenBank);
          list.push(stripeNordigenBank);

          // @ts-ignore
          list.push({
            name: 'Poster',
            id: 'Poster',
            logo_url: PosterLink,
            customHandler: onSetupBank,
            maxTakingDaysNumber,
            max_consent_days: maxTakingDaysNumber,
          });
        }

        if (
          selectedAccountId &&
          showFacturowniaIntegration &&
          currentCountry.alpha2Code === 'PL'
        ) {
          // @ts-ignore
          list.push({
            name: 'Fakturownia',
            id: 'Fakturownia',
            logo_url: FacturowniaLink,
            customHandler: onSetupBank,
            maxTakingDaysNumber,
            max_consent_days: maxTakingDaysNumber,
          });
        }

        if (selectedAccountId && currentCountry.alpha2Code === 'CZ') {
          // @ts-ignore
          list.push({
            name: 'iDoklad',
            id: 'iDoklad',
            logo_url: iDokladLink,
            customHandler: onSetupBank,
            maxTakingDaysNumber,
            max_consent_days: maxTakingDaysNumber,
          });
        }

        if (
          currentCountry.alpha2Code === 'NG' ||
          currentCountry.alpha2Code === 'PK'
        ) {
          list = useOnlyNordigenService
            ? defaultNordigenBanks.filter(
                (bank) => bank.id !== 'REVOLUT_REVOLT21',
              )
            : defaultSaltedgeBanks.filter(
                (bank) => bank.code !== 'revolut_oauth_client_si',
              );
        }

        // @ts-ignore
        list.push({
          name: 'Payoneer',
          id: 'Payoneer',
          integrationTypeId: IntegrationTypeId.Payoneer,
          logo_url: PayoneerLink,
          customHandler: onSetupBank,
        });

        list.sort(sortByName);
        setBankList(list);
        setFilteredBanks(list);
        setLoading(false);
      });
    } else {
      setFilteredBanks([]);
      setBankList([]);
      setLoading(false);
    }
  }, [
    onSetupBank,
    polandBanks,
    currentCountry,
    showINGsaltedge,
    selectedAccountId,
    showTestSaltedgeBanks,
    useOnlyNordigenService,
    showStripeBankIntegration,
    showFacturowniaIntegration,
  ]);

  useEffect(() => {
    if (currentCountryCode) {
      const country = data.find(
        (el) => normalize(el.alpha2Code) === normalize(currentCountryCode),
      );

      if (country) {
        setCurrentCountry(country);
      }
    }
  }, [currentCountryCode, data]);

  return (
    <Dialog isOpened title={t('bank.otherCountryBanks')} onClose={onClose}>
      <AutocompleteSelector
        data={data}
        optionSelectByKey="alpha2Code"
        // @ts-ignore
        onChange={handleSetCurrentCountry}
        // @ts-ignore
        value={currentCountry}
        label={t('bank.country')}
      />

      <>{loading && <Loader size="medium" />}</>

      <>
        {bankList.length > 10 && (
          <div className={classes.searchBlock}>
            <TextFieldComponent
              fullWidth
              value={searchValue}
              onChange={handleSearch}
              rootClassName={classes.searchBlockInput}
              placeholder={t('bank.search')}
            />
          </div>
        )}
      </>

      <div className={cn(classes.customBankContainer, classes.marginBottom48)}>
        {!!filteredBanks.length && currentCountry && (
          <ServiceProviderBanks
            data={filteredBanks}
            countryCode={currentCountry.alpha2Code}
            internalAccountId={selectedAccountId}
            onOpenSubscriptionDialog={onOpenSubscriptionDialog}
            onCloseIntegrationsDialog={onCloseIntegrationsDialog}
          />
        )}
        {!bankList.length && currentCountry?.alpha2Code === 'UA' && (
          <>
            <UkraineBanks onSetupBank={handleSetupUkrBank} />
            <AdditionalBanks
              onSetupBank={handleSetupUkrBank}
              showFondy={showFondy}
            />
            <ServiceProviderBanks
              data={banksList}
              countryCode="GB"
              internalAccountId={selectedAccountId}
              onOpenSubscriptionDialog={onOpenSubscriptionDialog}
              onCloseIntegrationsDialog={onCloseIntegrationsDialog}
            />
          </>
        )}
      </div>
    </Dialog>
  );
}

export default React.memo(CountriesDialog);
