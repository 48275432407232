import { useMemo } from 'react';
import { FEATURES } from '@finmap/core-constants';

import useUnleash from './useUnleash';

function useAdditionalLanguages() {
  const showCzechLanguage = useUnleash(FEATURES.SHOW_CZECH_LANGUAGE);
  const showItalianLanguage = useUnleash(FEATURES.SHOW_ITALIAN_LANGUAGE);
  const showFrenchLanguage = useUnleash(FEATURES.SHOW_FRENCH_LANGUAGE);
  const showSpanishLanguage = useUnleash(FEATURES.SHOW_SPANISH_LANGUAGE);
  const showPortugueseLanguage = useUnleash(FEATURES.SHOW_PORTUGUESE_LANGUAGE);
  const showTurkishLanguage = useUnleash(FEATURES.SHOW_TURKISH_LANGUAGE);
  const showKazakhLanguage = useUnleash(FEATURES.SHOW_KAZAKH_LANGUAGE);
  const showRussianLanguage = useUnleash(FEATURES.SHOW_RUSSIAN_LANGUAGE);
  const showUzbekLanguage = useUnleash(FEATURES.SHOW_UZBEK_LANGUAGE);
  const showBrazilLanguage = useUnleash(FEATURES.SHOW_BRAZIL_LANGUAGE);

  return useMemo(
    () => ({
      [FEATURES.SHOW_CZECH_LANGUAGE]: showCzechLanguage,
      [FEATURES.SHOW_ITALIAN_LANGUAGE]: showItalianLanguage,
      [FEATURES.SHOW_FRENCH_LANGUAGE]: showFrenchLanguage,
      [FEATURES.SHOW_SPANISH_LANGUAGE]: showSpanishLanguage,
      [FEATURES.SHOW_PORTUGUESE_LANGUAGE]: showPortugueseLanguage,
      [FEATURES.SHOW_TURKISH_LANGUAGE]: showTurkishLanguage,
      [FEATURES.SHOW_KAZAKH_LANGUAGE]: showKazakhLanguage,
      [FEATURES.SHOW_RUSSIAN_LANGUAGE]: showRussianLanguage,
      [FEATURES.SHOW_UZBEK_LANGUAGE]: showUzbekLanguage,
      [FEATURES.SHOW_BRAZIL_LANGUAGE]: showBrazilLanguage,
    }),
    [
      showCzechLanguage,
      showUzbekLanguage,
      showFrenchLanguage,
      showKazakhLanguage,
      showBrazilLanguage,
      showSpanishLanguage,
      showItalianLanguage,
      showTurkishLanguage,
      showRussianLanguage,
      showPortugueseLanguage,
    ],
  );
}

export default useAdditionalLanguages;
