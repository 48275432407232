import {
  AVAILABLE_IMPORT_TYPES,
  BaseImportParserV3,
  Config,
} from '@finmap/import-parsers/base-import-parser-v3';

export class VeloParser extends BaseImportParserV3 {
  // public debug: boolean = true;
  protected readonly config: Config = {
    [AVAILABLE_IMPORT_TYPES.PDF]: [
      {
        caseOptions: {
          defaultCurrency: 'PLN',
          preParserConfigs: {
            verticalAlign: 'middle',
            separatorsDistanceModifier: 0.7,
            interlineSpacingAccuracy: 10,
            prepareRawConfig: {
              findHeader: {
                from: [
                  {
                    word: {
                      eq: 'DATA',
                    },
                    nexWord: {
                      eq: 'DATA',
                    },
                  },
                ],
                to: [
                  {
                    word: {
                      eq: 'TRANSAKCJI',
                    },
                    prevWord: {
                      eq: 'TRANSAKCJI',
                    },
                  },
                ],
              },
              delete: [
                {
                  to: [
                    {
                      word: {
                        eq: 'DATA',
                      },
                      nexWord: {
                        eq: 'DATA',
                      },
                    },
                  ],
                  count: 1,
                },
                {
                  from: [
                    {
                      word: {
                        eq: 'Dokument',
                      },
                      nexWord: {
                        eq: 'wygenerowany',
                      },
                    },
                  ],
                  to: [
                    {
                      word: {
                        eq: 'roku',
                      },
                    },
                  ],
                  count: 999,
                },
              ],
              define: [
                {
                  isNum: true,
                  replace: [
                    {
                      from: 'PLN',
                      to: '',
                    },
                    {
                      from: ',',
                      to: '.',
                    },
                    {
                      from: ' ',
                      to: '',
                    },
                  ],
                },
                {
                  in: '.',
                },
              ],
            },
          },
          proceedCaseConfig: {
            fields: {
              date: {
                column: ['DATA\nTRANSAKCJI', 0],
                dateFormat: 'DD.MM.YYYY',
              },
              comment: {
                column: ['OPIS TRANSAKCJI', 2],
              },
              sum: {
                column: ['KWOTA\nTRANSAKCJI', 3],
                replace: [
                  {
                    from: 'PLN',
                    to: '',
                  },
                ],
              },
            },
          },
        },
      },
    ],
  };
}
