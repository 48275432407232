import { FormControlLabel, RadioGroup } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@finmap/core-constants';

import useUnleash from '../../../../hooks/useUnleash';
import { OperationType } from '../../../../store/operations/types';
import StyledRadio from '../Repeat/StyledRadio';
import { useStyles } from './RenderVariantRow.styles';
import { Props } from './RenderVariantRow.types';
import { DayOfPaymentVariant } from './types';

function RenderVariantRow(props: Props) {
  const {
    type,
    selectedVariant,
    onChangeVariant,
    isCustomCategory,
    isCompare,
  } = props;
  const { t } = useTranslation();

  const classes = useStyles();

  const useDateOfPaymentAsPeriod = useUnleash(
    FEATURES.USE_DATE_OF_PAYMENT_AS_PERIOD,
  );

  const handleChangeVariant = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeVariant(event.target.value as DayOfPaymentVariant);
    },
    [onChangeVariant],
  );

  if (!useDateOfPaymentAsPeriod || !isCustomCategory) {
    return null;
  }

  return (
    <RadioGroup aria-label="position" name="position" defaultValue="top">
      <div className={classes.variantRow}>
        <FormControlLabel
          classes={{
            root: classes.label,
            disabled: classes.disabled,
          }}
          value={DayOfPaymentVariant.singleDay}
          control={
            <StyledRadio
              rootOverrideClasses={classes.icon}
              value={DayOfPaymentVariant.singleDay}
              checked={selectedVariant === DayOfPaymentVariant.singleDay}
              onChange={handleChangeVariant}
              disabled={isCompare}
            />
          }
          label={
            type === OperationType.income
              ? t('operationDialogs.dayOfPayment.labelIncome')
              : t('operationDialogs.dayOfPayment.labelConsumption')
          }
        />
        <FormControlLabel
          classes={{
            root: classes.label,
            disabled: classes.disabled,
          }}
          value={DayOfPaymentVariant.period}
          control={
            <StyledRadio
              rootOverrideClasses={classes.icon}
              value={DayOfPaymentVariant.period}
              checked={selectedVariant === DayOfPaymentVariant.period}
              onChange={handleChangeVariant}
              disabled={isCompare}
            />
          }
          label={t('operationDialogs.dayOfPayment.labelPeriod')}
        />
      </div>
    </RadioGroup>
  );
}

export default React.memo(RenderVariantRow);
