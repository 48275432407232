import { Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@finmap/core-constants';

import MonoIcon from '../../../assets/images/svg/mono.svg';
import PumbIcon from '../../../assets/images/svg/payments/pumb.svg';
import UkrSibIcon from '../../../assets/images/svg/payments/ukrsib.svg';
import Privat24 from '../../../assets/images/svg/private-business.svg';
import useUnleash from '../../../hooks/useUnleash';
import { IntegrationTypeId } from '../../../store/integrations/types';
import { useStyles } from './styles';

function UkraineBanks({ onSetupBank }: { onSetupBank(event: any): void }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const showMonoAcquiringIntegration = useUnleash(
    FEATURES.SHOW_MONO_ACQUIRING_INTEGRATION,
  );
  const showUkrsibBankIntegration = useUnleash(
    FEATURES.SHOW_URKSIB_BANK_INTEGRATION,
  );

  return (
    <>
      <div
        id="MonoPersonal_my_accounts"
        className={classes.button}
        data-bankid={IntegrationTypeId.MonoPersonal}
        onClick={onSetupBank}
      >
        <div className={classes.flex}>
          <img alt="mono" src={MonoIcon} className={classes.icon} />
          <Typography
            id="MonoPersonal_my_accounts_text"
            className={classes.bankText}
          >
            {t('bank.mono')}
          </Typography>
        </div>
      </div>
      {showMonoAcquiringIntegration && (
        <div
          id="MonoAcquiring_my_accounts"
          className={classes.button}
          data-bankid={IntegrationTypeId.MonoAcquiring}
          onClick={onSetupBank}
        >
          <div className={classes.flex}>
            <img alt="mono" src={MonoIcon} className={classes.icon} />
            <Typography
              id="MonoAcquiring_my_accounts_text"
              className={classes.bankText}
            >
              {t('bank.monoAcquiring')}
            </Typography>
          </div>
        </div>
      )}
      <div
        id="Private24Business_my_accounts"
        className={classes.button}
        data-bankid={IntegrationTypeId.Private24Business}
        onClick={onSetupBank}
      >
        <div className={classes.flex}>
          <img alt="privat" src={Privat24} className={classes.icon} />
          <Typography className={classes.bankText}>
            {t('bank.privat24Business')}
          </Typography>
        </div>
      </div>
      <div
        id="Pumb_my_accounts"
        className={classes.button}
        data-bankid={IntegrationTypeId.Pumb}
        onClick={onSetupBank}
      >
        <div className={classes.flex}>
          <img alt="pumb" src={PumbIcon} className={classes.icon} />
          <Typography id="Pumb_my_accounts_text" className={classes.bankText}>
            {t('import.type.pumb')}
          </Typography>
        </div>
      </div>
      {showUkrsibBankIntegration && (
        <div
          id="UkrSib_my_accounts"
          className={classes.button}
          data-bankid={IntegrationTypeId.UkrSib}
          onClick={onSetupBank}
        >
          <div className={classes.flex}>
            <img alt="ukrsib" src={UkrSibIcon} className={classes.icon} />
            <Typography
              id="UkrSib_my_accounts_text"
              className={classes.bankText}
            >
              {t('import.type.ukrsibbank')}
            </Typography>
          </div>
        </div>
      )}
    </>
  );
}

export default React.memo(UkraineBanks);
